export const lk_item_status = {
    "0": {
        name: "初始化"
    },
    "1": {
        name: "交易成功"
    },
    "101": {
        name: "交易关闭"
    },
    "10": {
        name: "待支付"
    },
    "20": {
        name: "支付成功"
    },
    "100": {
        name: "待使用"
    },
    "200": {
        name: "预约中"
    },
    "201": {
        name: "已预约"
    },
    "300": {
        name: "退款中"
    },
    "301": {
        name: "已退款"
    },
    "400": {
        name: "履约中"
    },
    "401": {
        name: "已履约"
    },
}


export const lk_order_status = {
    "0": {
        name: "初始化"
    },
    "1": {
        name: "交易成功"
    },
    "100": {
        name: "待支付"
    },
    "101": {
        name: "订单关闭"
    },
    "200": {
        name: "支付成功"
    },
    "201": {
        name: "待使用"
    },
}

export const lk_verifyrecord_type = {
    "0": {
        name: "默认"
    },
    "1": {
        name: "用户自验"
    },
    "2": {
        name: "商家扫二维码"
    },
    "3":{
        name:'商家手动输入'
    },
    "4":{
        name:'开放平台API'
    },
    "5":{
        name:'商家来客端扫码'
    }
}