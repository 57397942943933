import request from '@/network/request.js'
/**
 * 获取来客的订单
 */
export function lists_lk_store(data) {
	return request({
		url: '/admin/tik_tok_lk_poi/poilist',
		method: 'post',
		data,
	})
}

