import request from '@/network/request.js'
/**
 * 获取核销列表
 */
export function lists_lk_check_ticket(data) {
	return request({
		url: '/admin/tik_tok_lk_check_ticket/check_ticket_list',
		method: 'post',
		data,
	})
}

/**
 * 撤销核销
 */
export function check_ticket_cancel(data) {
	return request({
		url: '/admin/tik_tok_lk_check_ticket/check_ticket_cancel',
		method: 'post',
		data,
	})
}


/**
 * 用code核销对应的码
 */
export function check_ticket_ope(data) {
	return request({
		url: '/admin/tik_tok_lk_check_ticket/check_ticket_ope',
		method: 'post',
		data,
	})
}